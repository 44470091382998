<template>
  <v-menu v-model="menu" rounded offset-y transition="slide-y-transition" :close-on-content-click=false right bottom>
    <template v-slot:activator="{ on: menu }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon
                 x-large
                 v-blur
                 v-bind="attrs"
                 v-on="{...on, ...menu}"
          >
            <v-icon>{{ !isDefaultFilter ? '$filterFull' : '$filterEmpty' }}</v-icon>
          </v-btn>
        </template>
        <span>Filters</span>
      </v-tooltip>

    </template>

    <!-- dropdown card -->
    <v-card rounded  :max-height="selectedDateRange !== -1 ? '554px' : '729px'" max-width="400">
      <v-form
          v-model="valid"
          ref="value"
          @submit.prevent="getFiltered"
          :disabled="loading"
      >
        <v-list dense>
          <v-subheader class="pb-1 font-weight-bold d-flex justify-space-between">
            <span class="align-self-start">Filters</span>
          </v-subheader>
          <div class="ml-1 mb-1 px-0">
              <v-radio-group class="ma-0 body-2 text-capitalize" dense v-model="selectedPatientStatus" :mandatory="true" row hide-details>
                <template v-slot:label>
                  <div class="pl-1">Patient Status</div>
                </template>
                <v-radio
                  class="px-1 body-2 ma-0"
                  :label="PatientStatuses.ACTIVE"
                  :value="PatientStatuses.ACTIVE"
                ></v-radio>
                <v-radio
                  class="px-1 body-2 ma-0"
                  :label="PatientStatuses.ALL"
                  :value="PatientStatuses.ALL"
                ></v-radio>
                <v-radio
                  class="px-1 body-2 ma-0"
                  :label="PatientStatuses.ARCHIVED"
                  :value="PatientStatuses.ARCHIVED"
                ></v-radio>
              </v-radio-group>
          </div>
          <v-list-item>
            <v-list-item-content>
              <v-select
                  v-model="selectedDateRange"
                  label="Date Range"
                  :items="FILTER_DATE_RANGE_SELECT"
                  hide-details
              >
              </v-select>
            </v-list-item-content>
          </v-list-item>
          <v-expand-transition>
            <div v-if="selectedDateRange === -1">
              <v-list-item>
                <v-list-item-content>
                  <date-field :required="selectedDateRange === -1"
                              :disable="selectedDateRange !== -1"
                              v-model="fromDate"
                              label="From Date"
                              :minDate="toDate ? moment(toDate).add(-94, 'days').format('YYYY-MM-DD') : undefined"
                              :maxDate="moment().format('YYYY-MM-DD')"
                              activePicker="MONTH"
                   />
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <date-field
                      :disable="selectedDateRange !== -1 || !fromDate"
                      v-model="toDate"
                      label="To Date (current if empty)"
                      :minDate="fromDate"
                      :maxDate="fromDate ? moment(fromDate).add(94, 'days').format('YYYY-MM-DD') : moment().format('YYYY-MM-DD')"
                      activePicker="MONTH"
                  />
                </v-list-item-content>
              </v-list-item>
            </div>
          </v-expand-transition>
          <v-list-item v-if="inputFields && inputFields.treatmentCoordinatorDisplay">
            <v-list-item-content>
              <v-select
                  v-model="selectedTreatmentCoordinators"
                  label="Treatment Coordinators"
                  :items="treatmentCoordinatorsSelect"
                  multiple
                  hide-details
              >
              </v-select>
            </v-list-item-content>
          </v-list-item>
          <v-list-item v-if="inputFields && inputFields.doctorDisplay">
            <v-list-item-content>
              <v-select
                v-model="selectedDoctors"
                label="Doctors"
                :items="doctorsSelect"
                multiple
                hide-details
              >
              </v-select>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-select
                  v-model="selectedOffices"
                  label="Offices"
                  :items="officesSelect"
                  multiple
                  hide-details
              >
              </v-select>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-select
                  v-model="selectedTreatmentPlanOption"
                  :disabled="routeName === 'dashboard'"
                  label="Treatment Plan Options"
                  :items="FILTER_TX_PLAN_OPTIONS_SELECT"
                  hide-details
              >
              </v-select>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-select
                  :disabled="routeName === 'dashboard'"
                  v-model="selectedStatuses"
                   label="Statuses"
                  :items="statusesSelect"
                  multiple
                  hide-details
              >
              </v-select>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-divider class="mt-2"/>
        <v-card-actions >
          <v-row dense class="rounded-lg" justify="space-between">
            <v-col>
              <v-btn :disabled="loading" :loading="loading && btnType === 'reset'" rounded outlined color="error"
                     type="button" block @click="btnType='reset';resetFilter()">Reset to Default
              </v-btn>
            </v-col>
            <v-col>
              <v-btn :disabled="loading" :loading="loading && btnType === 'filter'" rounded outlined color="primary"
                     type="button" block @click="btnType='filter';getFiltered()">Enable Filters
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>

      </v-form>
    </v-card>
  </v-menu>
</template>

<script lang="ts">
import {computed, defineComponent, reactive, ref, toRefs, watch} from "@vue/composition-api";
import {InputFields, Select} from "@shared/store";
import {OfficesGetters} from "@shared/store/practice/offices";
import {TreatmentCoordinatorsGetters} from "@shared/store/practice/treatment-coordinators";
import {usePatientService, useStore} from "@shared/providers";
import DateField from "../forms/common/fields/DateField.vue";
import {PatientsActions, PatientsGetters} from "@shared/store/patients";
import moment from "moment/moment";
import {
  FILTER_DATE_RANGE_SELECT,
  FILTER_TX_PLAN_OPTIONS_SELECT,
  PATIENT_FILTER_STATUSES_SELECT, PatientStatuses
} from "@shared/store/constants";
import {FormDefinition} from "../forms/interfaces";
import {VForm} from "../forms/types";
import {usePatientDataFilters} from "@main/composables/helpers/use-patient-data-filters";
import Vue from "vue";
import {defaultFromDate, getDateRange} from "@shared/functions/dateFunctions";
import {useRouteComponents} from "@main/composables/helpers";
import {ClientSubscriptionsGetters} from "@shared/store/financials/client-subscriptions";
import {InputFieldsGetters} from "@shared/store/input-fields";
import {WarningNotification} from "@shared/functions/NotificationFunctions";
import { DoctorsGetters } from "@shared/store/practice/doctors";

interface ToolbarFilterFormFields {
  selectedDateRange: number;
  toDate?: string;
  fromDate?: string;
  selectedTreatmentCoordinators?: string[];
  selectedDoctors?: string[];
  selectedOffices?: string[];
  selectedTreatmentPlanOption?: number;
  selectedPatientStatus: string;
}

interface ToolbarFilterForm extends FormDefinition {
  value: VForm | null;
  valid: boolean;
  fields: ToolbarFilterFormFields | null;
  rules: undefined;
}

/*
|---------------------------------------------------------------------
| Toolbar Apps Component
|---------------------------------------------------------------------
|
| Quickmenu for applications in the toolbar
|
*/
export default defineComponent({
  name: "ToolbarFilters",
  components: {
    DateField,
  },
  props: {
    isDefaultFilter: Boolean,
  },
  setup() {
    const store = useStore();
    const service = usePatientService();
    const form = reactive<ToolbarFilterForm>({
      value: null,
      valid: false,
      fields: null,
      rules: undefined,
    });
    const {routeName} = useRouteComponents();
    const officesSelect = computed<Select[]>(
        () => store.getters[OfficesGetters.OFFICES_WITH_EMPTY_SELECT]
    );
    const inputFields = computed<InputFields>(
        () => store.getters[InputFieldsGetters.INPUT_FIELDS]
    );

    const treatmentCoordinatorsSelect = computed<Select[]>(
        () => (store.getters[TreatmentCoordinatorsGetters.TREATMENT_COORDINATORS_WITH_EMPTY_SELECT])
    );
    const doctorsSelect = computed<Select[]>(
      () => store.getters[DoctorsGetters.DOCTORS_WITH_EMPTY_SELECT]
    );
    const loading = computed<boolean>(
        () => store.getters[PatientsGetters.LOADING]
    );

    const areIntakeAndAnytimeFormsEnabled = computed<boolean>(
        () => store.getters[ClientSubscriptionsGetters.ARE_INTAKE_AND_ANYTIME_FORMS_ENABLED]
    );
    const areFeePresentationsEnabled = computed<boolean>(
        () => store.getters[ClientSubscriptionsGetters.ARE_FEE_PRESENTATIONS_ENABLED]
    );

    const {patientDataFilters} = usePatientDataFilters();
    const menu = ref<boolean>(false);
    const selectedOffices = ref<string[]>([]);
    const selectedTreatmentCoordinators = ref<string[]>([]);
    const selectedDoctors = ref<string[]>([]);
    const selectedTreatmentPlanOption = ref<number>(-1);
    const selectedStatuses = ref<string[] | undefined>([]);
    //date related
    const selectedDateRange = ref<number>(30);
    const btnType = ref<string | undefined>(undefined);

    const fromDate = ref<string | Date | undefined>(defaultFromDate);
    const toDate = ref<string | Date | undefined>(undefined);

    const selectedPatientStatus = ref<string>(PatientStatuses.ACTIVE);

    const statusesSelect = computed<{ text:string, value: string, disabled: boolean, subscription: undefined | string }[]>(
        () => {
          let items = [];
          for (const item of PATIENT_FILTER_STATUSES_SELECT) {
            if ((item?.value?.includes('intake') || item?.value?.includes('anytime')) && areIntakeAndAnytimeFormsEnabled?.value) {
              items.push(item);
              continue;
            }
            else if ((item?.value?.includes('start') || item?.value?.includes('treatment-plans'))&& areFeePresentationsEnabled?.value) {
              items.push(item);
              continue;
            }
            else if(item?.value?.includes('patients') || item?.value?.includes('pts')) {
              items.push(item);
              continue;
            }
          }
          return items;
        }
    )

    async function getFiltered() {
      if (form.valid) {
        const dateRangeValues = getDateRange(selectedDateRange.value);
        fromDate.value = dateRangeValues?.fromDate;
        toDate.value = dateRangeValues?.toDate;

        if(!toDate.value) {
          const maxMomentDate = moment(fromDate.value as string).add(94, 'days');
          if(maxMomentDate.isBefore()) {
            await WarningNotification(store.dispatch, "Date range has to be less than - 3 MONTHS");
            return;
          }
        }
        await store.dispatch(PatientsActions.LIST_PATIENTS, {
          service,
          fromLastTouchedDate: fromDate.value,
          toLastTouchedDate: toDate.value && moment.utc(toDate.value, "YYYY-MM-DD").isSame(moment(), "day") ? undefined : toDate.value,
          patientStatus: selectedPatientStatus.value,
          treatmentCoordinatorIds: selectedTreatmentCoordinators.value,
          doctorIds: selectedDoctors.value,
          includeTreatmentPlans: selectedTreatmentPlanOption.value,
          officeIds: selectedOffices.value,
          statuses: selectedStatuses.value,
          dateRange: selectedDateRange.value,
        });
        btnType.value = undefined;
        menu.value = false;
      } else if (form.value) {
        form.value.validate();
      }
    }

    async function resetFilter() {
      defaultValues();
      await getFiltered();
    }

    function defaultValues() {
      fromDate.value = defaultFromDate;
      toDate.value = undefined;
      selectedPatientStatus.value = PatientStatuses.ACTIVE;
      selectedDateRange.value = 30;
      selectedTreatmentCoordinators.value = [];
      selectedDoctors.value = [];
      selectedOffices.value = [];
      selectedTreatmentPlanOption.value = -1;
      selectedStatuses.value = [];
    }

    watch(
        () => [patientDataFilters?.value],
        async () => {

          await Vue.nextTick();
          if(typeof patientDataFilters?.value === 'undefined') {
            defaultValues();
            return;
          }

          const dateRangeValues = getDateRange(selectedDateRange.value);
          fromDate.value = dateRangeValues?.fromDate;
          toDate.value = dateRangeValues?.toDate;

          if(!toDate.value) {
            const maxMomentDate = moment(fromDate.value as string).add(94, 'days');
            if(maxMomentDate.isBefore()) {
              fromDate.value = defaultFromDate;
              toDate.value = undefined;
              selectedDateRange.value = 30;
            }
          }

          toDate.value = toDate.value && moment.utc(toDate.value, "YYYY-MM-DD").isSame(moment(), "day") ? undefined : toDate.value;
          selectedPatientStatus.value = typeof patientDataFilters?.value?.patientStatus === 'string' ? patientDataFilters?.value?.patientStatus : PatientStatuses.ACTIVE;
          selectedDateRange.value = typeof patientDataFilters?.value?.dateRange === 'number' ? patientDataFilters?.value?.dateRange : 30;
          selectedTreatmentCoordinators.value = patientDataFilters?.value?.treatmentCoordinatorIds || [];
          selectedDoctors.value = patientDataFilters?.value?.doctorIds || [];
          selectedOffices.value = patientDataFilters?.value?.officeIds || [];
          selectedTreatmentPlanOption.value = typeof patientDataFilters?.value?.includeTreatmentPlans !== 'undefined' ? patientDataFilters?.value?.includeTreatmentPlans : -1;
          selectedStatuses.value = patientDataFilters?.value?.statuses;

        },
        {immediate: true}
    );

    return {
      officesSelect,
      selectedOffices,
      treatmentCoordinatorsSelect,
      selectedTreatmentCoordinators,
      doctorsSelect,
      selectedDoctors,
      selectedDateRange,
      FILTER_DATE_RANGE_SELECT,
      fromDate,
      toDate,
      defaultFromDate,
      getFiltered,
      resetFilter,
      loading,
      selectedPatientStatus,
      selectedTreatmentPlanOption,
      FILTER_TX_PLAN_OPTIONS_SELECT,
      btnType,
      moment,
      ...toRefs(form),
      statusesSelect,
      selectedStatuses,
      routeName,
      areIntakeAndAnytimeFormsEnabled,
      areFeePresentationsEnabled,
      inputFields,
      menu,
      PatientStatuses
    };
  }
});
</script>
